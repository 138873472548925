import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    emailRegex,
    nameRegex,
    userNameRegex
} from "utils/constants";
import axiosInstance from "utils/services/api";
import { removeFirstMatch } from "utils/services/commonServices";
import { formatDateWithAMPM, getFormattedTime } from "utils/services/dateUtils";
import * as Yup from "yup";
import { Country, State, City } from 'country-state-city';

const AddSports = () => {
    const [countrycode, setCountrycode] = useState("+966");

    const [country, setCountry] = useState(Country.getAllCountries())
    const [state, setState] = useState([])

    const [showPassword, setShowPassword] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const navigate = useNavigate();

    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
    const [updateloading, setUpdateLoading] = useState(false);
    const [saveUserId, setSaveUserId] = useState("");

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Owner Name is required")
            .trim()
            .matches(
                nameRegex,
                "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the Owner Name"
            )
            .test(
                "no-multiple-spaces",
                "Owner Name should not have multiple spaces",
                (value) => !/\s{2,}/.test(value)
            )
            .test(
                "no-only-special-chars",
                "Owner Name should not consist entirely of special characters",
                (value) => !/^[-_\\.]+$/.test(value)
            )
            .max(20, "Owner Name must not exceed 20 characters"),
        userName: Yup.string()
            .required("User Name is required")
            .matches(
                userNameRegex,
                "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the User Name"
            )
            .test(
                "no-only-special-chars",
                "User Name should not consist entirely of special characters",
                (value) => !/^[-_\\.]+$/.test(value)
            )
            .max(20, "User Name must not exceed 20 characters")
            .test(
                "no-multiple-spaces",
                "User Name should not have multiple spaces",
                (value) => !/\s{2,}/.test(value)
            ),
        email: Yup.string()
            .matches(emailRegex, "Invalid email address")
            .required("Email is required"),

        phoneNumber: Yup.string()
            .required("Mobile number is required")
            .test("checkEmptyValue", "Mobile number is required", function (value) {
                const { countryCode } = this.parent;
                return `+${value}` !== countryCode
            })
            .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
                const { countryCode } = this.parent;
                return removeFirstMatch(value, countryCode?.split('+')?.[1])?.length >= 4
            })
            .matches(/^[0-9]+$/, "Mobile number is must be only digits"),
        password: Yup.string()
            .required("Password is required")
            .trim()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/,
                "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#."
            ),
        // businessName: Yup.string().trim().required("Business Name is required"),
        country: Yup.string().trim().required("Country Name is required"),
        city: Yup.string().trim().required("City Name is required"),
        businessAddress: Yup.string().trim(),
        businessDescription: Yup.string().trim(),
        website: Yup.string().trim(),
        instagram: Yup.string().trim(),
        WhatsApp: Yup.string().trim(),
        snapchat: Yup.string().trim(),

        courtName: Yup.string().trim().required("Court Name is required"),
        // courtLocation: Yup.string().trim().required("Court Location is required"),
        courtDescription: Yup.string()
            .trim()
            .required("Court Description is required"),
        courtWebsite: Yup.string().trim(),
        courtInstagram: Yup.string().trim(),
        courtWhatsApp: Yup.string().trim(),
        courtSnapchat: Yup.string().trim(),

        category: Yup.array().min(1, "Please select at least one category"),
        facilities: Yup.array(),
        gender: Yup.string().required("Please select at least one gender"),
        gameType: Yup.string().required("Please select at least one type"),

        openTime: Yup.date().test(
            "not-equal",
            "Please Enter Valid Open Time",
            function (openTime) {
                const { closeTime } = this.parent;
                const formattedOpenTime = getFormattedTime(openTime);
                const formattedCloseTime = getFormattedTime(closeTime);

                if (formattedOpenTime && formattedCloseTime) {
                    if (formattedOpenTime > formattedCloseTime) {
                        return false;
                    }
                }

                return true;
            }
        ),
        closeTime: Yup.date().test(
            "not-equal",
            "Open Time and Close Time should not be the same",
            function (closeTime) {
                const { openTime } = this.parent;
                const formattedOpenTime = getFormattedTime(openTime);
                const formattedCloseTime = getFormattedTime(closeTime);
                return closeTime ? formattedCloseTime !== formattedOpenTime : true;
            }
        ),
    });
    const fetchRole = async () => {
        try {
            const response = await axiosInstance.get("/roles");
            const data = response?.data?.data;
            setRoleOptions(data);

            const customerRole = data?.find(
                (role) => role?.name === "Sport Center Owner"
            );

            if (customerRole) {
                setSelectedRole(customerRole?.id);
            }
        } catch (error) {
            console.error("Error fetching role data:", error);
        }
    };

    useEffect(() => {
        fetchRole();
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const initialValues = {
        roleId: selectedRole,
        name: "",
        userName: "",
        email: "",
        countryCode: "+966",
        phoneNumber: "",
        password: "",
        businessName: "",
        businessAddress: "",
        businessDescription: "",
        Website: "",
        Instagram: "",
        WhatsApp: "",
        Snapchat: "",
        country: "",
        city: "",
        courtName: "",
        // courtLocation: "",
        courtDescription: "",
        courtWebsite: "",
        courtInstagram: "",
        courtWhatsApp: "",
        courtSnapchat: "",

        category: [],
        facilities: [],
        gender: "",
        gameType: "",
        closeTime: "",
        openTime: "",
    };

    const handleFormSubmit = async (values) => {
        try {
            setUpdateLoading(true);

            const {
                businessAddress,
                businessName,
                businessDescription,
                Website,
                Instagram,
                WhatsApp,
                Snapchat,
                courtName,
                country,
                city,
                facilities,
                courtDescription,
                category,
                gender,
                gameType,
                closeTime,
                openTime,
                courtInstagram,
                courtSnapchat,
                courtWebsite,
                courtWhatsApp,
                ...post
            } = values;

            const userData = {
                businessInformation: {
                    name: businessName || "",
                    address: businessAddress || "",
                    description: businessDescription || "",
                },
                socialMedialinks: {
                    Website: Website || "",
                    Instagram: Instagram || "",
                    WhatsApp: WhatsApp || "",
                    Snapchat: Snapchat || "",
                },
                roleId: selectedRole,
                countryCode: post.countryCode,
                phoneNumber: removeFirstMatch(post.phoneNumber, post.countryCode?.split('+')?.[1]) ?? ''
            };
            let userId = saveUserId;
            if (!userId) {


                const userResponse = await axiosInstance.post("/users", {
                    ...post,
                    ...userData,
                });

                if (userResponse?.data?.success) {
                    userId = userResponse?.data?.data?.id;
                    setSaveUserId(userId);

                    const courtPost = {
                        userId,
                        name: courtName,
                        description: courtDescription,
                        category,
                        gender,
                        facilities: facilities.map((a) => a.toLowerCase()),
                        type: gameType,
                        openTime: formatDateWithAMPM(openTime, "h:mm A"),
                        closeTime: formatDateWithAMPM(closeTime, "h:mm A"),
                        socialMedia: {
                            Website: courtWebsite,
                            Instagram: courtInstagram,
                            WhatsApp: courtWhatsApp,
                            Snapchat: courtSnapchat,
                        },
                    };

                    const courtResponse = await axiosInstance.post("/courts", courtPost);

                    if (courtResponse?.data?.success) {
                        toast.success(courtResponse?.data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        navigate("/admin/sports-owner-management");
                        return;
                    }
                }
            }

            // Display error toast if userId is not obtained or there's an issue
            toast.error("Add Sport Owner Failed.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            toast.error(error?.response?.data?.message || "Add Sport Owner Failed.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setUpdateLoading(false);
        }
    };

    const facilitiesOptions = [
        { label: "Balls", value: "Balls" },
        { label: "Rackets", value: "Rackets" },
        { label: "Showers", value: "Showers" },
        { label: "Water", value: "Water" },
        { label: "Toilets", value: "Toilets" },
    ];
    const genderOptions = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Both", value: "Both" },
    ];
    const gameTypeOptions = [
        { label: "Indoor", value: "Indoor" },
        { label: "Outdoor", value: "Outdoor" },
        { label: "Both", value: "Both" },
    ];
    const categorieOptions = [
        { label: "Padel", value: "Padel" },
        { label: "Pickleball", value: "Pickleball" },
        { label: "Badboll", value: "Badboll" },
        { label: "Football", value: "Football" },
        { label: "Basketball", value: "Basketball" },
        { label: "Volleyball", value: "Volleyball" },
        { label: "Others", value: "Others" },
    ];

    return (
        <div className="mt-3">
            <div className="flex items-center justify-center">
                <div className="w-full bg-white p-4 shadow-lg">
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => handleFormSubmit(values)}
                            enableReinitialize
                        >
                            {({
                                values,
                                handleBlur,
                                handleChange,
                                setFieldValue,
                                errors,
                            }) => (
                                <Form className="flex flex-col px-5">
                                    {/* Sports Owner Details */}
                                    <div className="sm:flex sm:items-center">
                                        <div className="w-full lg:w-full">
                                            <div
                                                className="rounded-b border-t-4 border-[#99C2D8] bg-[#99c2d82f] px-4 py-3 text-teal-900 shadow-sm"
                                                role="alert"
                                            >
                                                <div className="flex items-center">
                                                    <div className="py-1">
                                                        <svg
                                                            className="fill-current mr-4 h-6 w-6 text-teal-500"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <p className="font-bold">Add Owner Details</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2">
                                                <div className="mb-2">
                                                    <label className="fw-bold block text-gray-700">
                                                        Roles:
                                                    </label>
                                                    <select
                                                        id="roleSelect"
                                                        className="w-full rounded-md border px-3 py-2"
                                                        value={selectedRole}
                                                        disabled={true}
                                                    >
                                                        <option value="Sports Owner Management">
                                                            Sports Owner Management
                                                        </option>
                                                    </select>
                                                </div>

                                                <div className="mb-2">
                                                    <label className="fw-bold block text-gray-700">
                                                        {`Owner's Name:`}{" "}
                                                        <span className="text-red-500">*</span>
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        className="w-full rounded-md border px-3 py-2"
                                                        placeholder="Enter Owner Name"
                                                        maxLength={20}
                                                    />
                                                    <ErrorMessage
                                                        name="name"
                                                        component="div"
                                                        className="text-red-500"
                                                    />
                                                </div>

                                                <div className="mb-2">
                                                    <label className="fw-bold block text-gray-700">
                                                        User Name: <span className="text-red-500">*</span>
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="userName"
                                                        placeholder="Enter User Name"
                                                        className="w-full rounded-md border px-3 py-2"
                                                        value={values.userName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        maxLength={20}
                                                    />
                                                    <ErrorMessage
                                                        name="userName"
                                                        component="div"
                                                        className="text-red-500"
                                                    />
                                                </div>

                                                <div className="mb-2">
                                                    <label className="fw-bold block text-gray-700">
                                                        Email Address:{" "}
                                                        <span className="text-red-500">*</span>
                                                    </label>
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        placeholder="Enter Email Address"
                                                        className="w-full rounded-md border px-3 py-2"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="text-red-500"
                                                    />
                                                </div>

                                                <div className="mb-2 relative">
                                                    <label className="fw-bold block text-gray-700">
                                                        Password: <span className="text-red-500">*</span>
                                                    </label>
                                                    <div className="relative">
                                                        <Field
                                                            type={showPassword ? "text" : "password"}
                                                            name="password"
                                                            placeholder="Enter Password here"
                                                            className="w-full rounded-md border px-3 py-2 pr-10"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <span
                                                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                                                        </span>
                                                    </div>
                                                    <ErrorMessage
                                                        name="password"
                                                        component="div"
                                                        className="text-red-500"
                                                    />
                                                </div>


                                                <div className="mb-2">
                                                    <label className="fw-bold block text-gray-700">
                                                        Mobile Number:{" "}
                                                        <span className="text-red-500">*</span>
                                                    </label>
                                                    <div className="relative">
                                                        <div className="relative">
                                                            <PhoneInput
                                                                country={"sa"}
                                                                disableDropdown={false}
                                                                countryCodeEditable={false}
                                                                enableSearch={false}
                                                                placeholder="Enter Mobile Number"
                                                                value={values.phoneNumber}
                                                                onChange={(phone, dialCode, c, f) => {
                                                                    setFieldValue("countryCode", `+${dialCode.dialCode}`);
                                                                    setFieldValue("phoneNumber", phone);
                                                                    if (
                                                                        dialCode.format !==
                                                                        String(phone).replace(/[0-9]/g, ".")
                                                                    ) {
                                                                        setIsValidPhoneNumber(false);
                                                                    } else {
                                                                        setIsValidPhoneNumber(true);
                                                                    }
                                                                }}
                                                                onBlur={async (e, dialCode) => {
                                                                    if (
                                                                        dialCode.format !==
                                                                        String(e.target.value).replace(
                                                                            /[0-9]/g,
                                                                            "."
                                                                        )
                                                                    ) {
                                                                        setIsValidPhoneNumber(false);
                                                                    } else {
                                                                        setIsValidPhoneNumber(true);
                                                                    }
                                                                }}
                                                                containerStyle={{
                                                                    border: "1px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    padding: "5px",
                                                                }}
                                                                inputStyle={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    outline: "none",
                                                                    height: "10px",
                                                                }}
                                                            />
                                                            {errors?.phoneNumber && (
                                                                <ErrorMessage
                                                                    name="phoneNumber"
                                                                    component="div"
                                                                    className="text-red-500"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Sports Owner Details End */}

                                    {/* Sports Owner : Business Details */}
                                    <div
                                        className="rounded-b border-t-4 border-[#99C2D8] bg-[#99c2d82f] px-4 py-3 text-teal-900 shadow-sm"
                                        role="alert"
                                    >
                                        <div className="flex items-center">
                                            <div className="py-1">
                                                <svg
                                                    className="fill-current mr-4 h-6 w-6 text-teal-500"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                                                </svg>
                                            </div>
                                            <div>
                                                <p className="font-bold">Add Business Details</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row">
                                        <div className="w-full p-4 sm:w-1/2">
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Business Name:
                                                    {/* <span className="text-red-500">*</span> */}
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="businessName"
                                                    placeholder="Enter Business Name"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                {/* <ErrorMessage
                                                    name="businessName"
                                                    component="div"
                                                    className="text-red-500"
                                                /> */}
                                            </div>
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Business Address:
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="businessAddress"
                                                    placeholder="Enter Business Address"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="businessAddress"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Business Description:
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    placeholder="Enter Business Description"
                                                    name="businessDescription"
                                                    className="w-full rounded-md border px-3 py-2"
                                                    rows="4"
                                                />
                                                <ErrorMessage
                                                    name="businessDescription"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full p-4 sm:w-1/2">
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Website:
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="Website"
                                                    placeholder="Enter Website URL"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="Website"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Instagram:
                                                </label>
                                                <Field
                                                    type="text"
                                                    placeholder="Enter Instagram URL"
                                                    name="Instagram"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="Instagram"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    WhatsApp Number/Link:
                                                </label>
                                                <Field
                                                    type="tel"
                                                    name="WhatsApp"
                                                    placeholder="Enter WhatsApp Number/Link"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="WhatsApp"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Snapchat:
                                                </label>
                                                <Field
                                                    type="text"
                                                    placeholder="Enter Snapchat URL"
                                                    name="Snapchat"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="Snapchat"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Location:
                                                </label>
                                                <Field
                                                    type="text"
                                                    placeholder="Enter Location"
                                                    name="location"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="location"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Phone:
                                                </label>
                                                <Field
                                                    type="text"
                                                    placeholder="Enter Phone Number"
                                                    name="phone"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="phone"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    {/* Sports Owner : Business Details End*/}

                                    {/* Court Details*/}
                                    <div
                                        className="rounded-b border-t-4 border-[#99C2D8] bg-[#99c2d82f] px-4 py-3 text-teal-900 shadow-sm"
                                        role="alert"
                                    >
                                        <div className="flex items-center">
                                            <div className="py-1">
                                                <svg
                                                    className="fill-current mr-4 h-6 w-6 text-teal-500"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                                                </svg>
                                            </div>
                                            <div>
                                                <p className="font-bold">Add Court Details</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row">
                                        <div className="w-full p-4 sm:w-1/2">
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Court Name: <span className="text-red-500">*</span>
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="courtName"
                                                    placeholder="Enter Court Name"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="courtName"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Court Description:{" "}
                                                    <span className="text-red-500">*</span>
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    placeholder="Enter Court Description"
                                                    name="courtDescription"
                                                    className="w-full rounded-md border px-3 py-2"
                                                    rows="5"
                                                />
                                                <ErrorMessage
                                                    name="courtDescription"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Country: <span className="text-red-500">*</span>
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="country"
                                                    value={values.country} // Ensure Formik value is set correctly
                                                    onChange={e => {
                                                        const selectedCountry = e.target.value;
                                                        const stateData = State.getStatesOfCountry(selectedCountry);
                                                        setState(stateData);
                                                        setFieldValue('country', selectedCountry);
                                                    }}
                                                    className="w-full rounded-md border px-3 py-2"
                                                >
                                                    <option value="" label="Select a country" />
                                                    {country.map((e) => (
                                                        <option key={e.isoCode} value={e.isoCode}>
                                                            {e.name}
                                                        </option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage
                                                    name="country"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    City: <span className="text-red-500">*</span>
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="city"
                                                    className="w-full rounded-md border px-3 py-2" >
                                                    <option value="" label="Select a City" />
                                                    {state.map((e) => (
                                                        <option key={e.isoCode} value={e.isoCode}>
                                                            {e.name}
                                                        </option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage
                                                    name="city"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Website:
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="courtWebsite"
                                                    placeholder="Enter Website URL"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="courtWebsite"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Instagram:
                                                </label>
                                                <Field
                                                    type="text"
                                                    placeholder="Enter Instagram URL"
                                                    name="courtInstagram"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="courtInstagram"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    WhatsApp Number/Link:
                                                </label>
                                                <Field
                                                    type="tel"
                                                    name="courtWhatsApp"
                                                    placeholder="Enter WhatsApp Number/Link"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="courtWhatsApp"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label className="fw-bold block text-gray-700">
                                                    Snapchat:
                                                </label>
                                                <Field
                                                    type="text"
                                                    placeholder="Enter Snapchat URL"
                                                    name="courtSnapchat"
                                                    className="w-full rounded-md border px-3 py-2"
                                                />
                                                <ErrorMessage
                                                    name="courtSnapchat"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full p-4 sm:w-1/2">
                                            <div className="mb-2">
                                                <div
                                                    className="mb-4 flex items-center rounded-lg border border-blue-300 bg-blue-50 p-2 text-sm text-blue-800 dark:border-blue-800 dark:bg-gray-800 dark:text-blue-400"
                                                    role="alert"
                                                >
                                                    <label
                                                        htmlFor="category"
                                                        className="fw-bold block text-gray-700"
                                                    >
                                                        Category: <span className="text-red-500">*</span>
                                                    </label>
                                                </div>

                                                <div className="grid grid-cols-3 gap-4">
                                                    {categorieOptions.map((option) => (
                                                        <div
                                                            key={option.value}
                                                            className="flex items-center"
                                                        >
                                                            <Field
                                                                type="checkbox"
                                                                name="category"
                                                                value={option.value}
                                                                id={option.value}
                                                                className="mr-2"
                                                            />
                                                            <label htmlFor={option.value}>
                                                                {option.label}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <ErrorMessage
                                                    name="category"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <div
                                                    className="mb-4 flex items-center rounded-lg border border-blue-300 bg-blue-50 p-2 text-sm text-blue-800 dark:border-blue-800 dark:bg-gray-800 dark:text-blue-400"
                                                    role="alert"
                                                >
                                                    <label
                                                        htmlFor="facilities"
                                                        className="fw-bold block text-gray-700"
                                                    >
                                                        Facilities:
                                                    </label>
                                                </div>

                                                <div className="grid grid-cols-3 gap-4">
                                                    {facilitiesOptions.map((option) => (
                                                        <div
                                                            key={option.value}
                                                            className="flex items-center"
                                                        >
                                                            <Field
                                                                type="checkbox"
                                                                name="facilities"
                                                                value={option.value}
                                                                id={option.value}
                                                                className="mr-2"
                                                            />
                                                            <label htmlFor={option.value}>
                                                                {option.label}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <ErrorMessage
                                                    name="facilities"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <div
                                                    className="mb-4 flex items-center rounded-lg border border-blue-300 bg-blue-50 p-2 text-sm text-blue-800 dark:border-blue-800 dark:bg-gray-800 dark:text-blue-400"
                                                    role="alert"
                                                >
                                                    <label
                                                        htmlFor="gender"
                                                        className="fw-bold block text-gray-700"
                                                    >
                                                        Gender: <span className="text-red-500">*</span>
                                                    </label>
                                                </div>
                                                <div className="grid grid-cols-3 gap-4">
                                                    {genderOptions?.map((option) => (
                                                        <div
                                                            key={option.value}
                                                            className="flex items-center"
                                                        >
                                                            <Field
                                                                type="radio"
                                                                name="gender"
                                                                value={option.value}
                                                                id={option.value}
                                                                className="mr-2"
                                                            />
                                                            <label htmlFor={"gender"}>{option.label}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <ErrorMessage
                                                    name="gender"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <div
                                                    className="mb-4 flex items-center rounded-lg border border-blue-300 bg-blue-50 p-2 text-sm text-blue-800 dark:border-blue-800 dark:bg-gray-800 dark:text-blue-400"
                                                    role="alert"
                                                >
                                                    <label
                                                        htmlFor="gameType"
                                                        className="fw-bold mx-2 block text-gray-700"
                                                    >
                                                        Type: <span className="text-red-500">*</span>
                                                    </label>
                                                </div>
                                                <div className="grid grid-cols-3 gap-4">
                                                    {gameTypeOptions?.map((option) => (
                                                        <div
                                                            key={option.value}
                                                            className="flex items-center"
                                                        >
                                                            <Field
                                                                type="radio"
                                                                name="gameType"
                                                                value={option.value}
                                                                id={option.value}
                                                                className="mr-2"
                                                            />
                                                            <label htmlFor={"Type"}>{option.label}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <ErrorMessage
                                                    name="gameType"
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>

                                            <div>
                                                <div className="flex space-x-4">
                                                    <div className="flex flex-1 flex-col">
                                                        <div
                                                            className="mb-4 flex items-center rounded-lg border border-blue-300 bg-blue-50 p-2 text-sm text-blue-800 dark:border-blue-800 dark:bg-gray-800 dark:text-blue-400"
                                                            role="alert"
                                                        >
                                                            <label className="fw-bold mx-2 block text-gray-700">
                                                                Court Timing:{" "}
                                                                <span className="text-red-500">*</span>
                                                            </label>
                                                        </div>
                                                        <div className="flex flex-col md:flex-row md:space-x-4">
                                                            <div className="mb-2">
                                                                <ReactDatePicker
                                                                    placeholderText="Start Time"
                                                                    selected={values.openTime}
                                                                    selectsStart
                                                                    startDate={values.openTime}
                                                                    endDate={values.closeTime}
                                                                    onChange={(date) => {
                                                                        setFieldValue(`openTime`, date);
                                                                        setFieldValue(`closeTime`, "");
                                                                    }}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeFormat="h:mm aa"
                                                                    dateFormat="h:mm aa"
                                                                    timeIntervals={30}
                                                                    className="w-full rounded-md border px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                                    required
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <ReactDatePicker
                                                                    placeholderText="End Time"
                                                                    selected={values.closeTime}
                                                                    selectsEnd
                                                                    startDate={values.openTime}
                                                                    endDate={values.closeTime}
                                                                    onChange={(date) => {
                                                                        setFieldValue(`closeTime`, date);
                                                                    }}
                                                                    disabled={values?.openTime === ""}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeFormat="h:mm aa"
                                                                    dateFormat="h:mm aa"
                                                                    timeIntervals={30}
                                                                    minTime={
                                                                        values?.openTime
                                                                            ? new Date(values.openTime.getTime() + 5 * 60000) // Adding 5 minutes
                                                                            : new Date(0, 0, 0, 0, 0)
                                                                    }
                                                                    maxTime={
                                                                        new Date(new Date().setHours(23, 59, 59))
                                                                    }

                                                                    className="w-full rounded-md border px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                                    required
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex space-x-4">
                                                    <div className="flex flex-col">
                                                        <ErrorMessage
                                                            name={`openTime`}
                                                            component="div"
                                                            className="text-red-500"
                                                        />
                                                        <ErrorMessage
                                                            name={`closeTime`}
                                                            component="div"
                                                            className="text-red-500"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Court Details End */}

                                    <div className="flex justify-end">
                                        <div className="flex items-center gap-4">
                                            <button
                                                type="submit"
                                                className="bg-theme cursor-pointer rounded-md px-5 py-2 text-white"
                                                disabled={updateloading}
                                            >
                                                {updateloading ? "Submitting..." : "Submit"}
                                            </button>
                                            <button
                                                type="button"
                                                className="cursor-pointer rounded-md bg-gray-400 px-5 py-2 text-white"
                                                disabled={updateloading}
                                                onClick={() =>
                                                    navigate("/admin/sports-owner-management")
                                                }
                                            >
                                                Cancle
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSports;
